import React from 'react';
import { OpenRequests } from './OpenRequests.tsx';
import { PatientSearch } from './PatientSearch.tsx';

export const FrontDeskOverview: React.FC = () => {
  return (
    <>
      <OpenRequests />
      <PatientSearch showNewButton={true} />
    </>
  );
};
