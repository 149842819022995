import React, { memo, useCallback, useMemo } from 'react';
import { Button, Checkbox, Col, theme, Tooltip } from 'antd';
import { InfoCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { css } from '@emotion/css';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../../graphql/generated/graphql.ts';
import { SheetData, SheetGroup, SheetParameter } from '../../../../graphql/sheet';

const SheetParameterCol: React.FC<{
  sheet: SheetData;
  group: SheetGroup;
  sheetParam: SheetParameter;
  requestableParam?: ParameterSelection_RequestableParameterFragment;
  available: boolean;
  compatible: boolean;
  selected: boolean;
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({
  sheet,
  group,
  sheetParam,
  requestableParam,
  available,
  compatible,
  selected,
  onAdd,
  onRemove,
  onInfo,
  reorder,
  flipParamNames,
}) => {
  const { token } = theme.useToken();

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      e.target.checked
        ? onAdd([
            {
              parameterId: requestableParam!.id,
              preferredBillingType: null,
              preferredDiagnoseIds: [],
            },
          ])
        : onRemove(requestableParam!.id);
    },
    [onAdd, onRemove, requestableParam]
  );

  const id = useMemo(() => `${sheet.name}_${group.name}_${sheetParam.id}`, [group, sheet, sheetParam]);

  const styles = useMemo(
    () => ({
      col: css`
        display: flex;
        gap: 8px;
        align-items: center;
      `,
      label: css`
        display: flex;
        min-width: 0;
        cursor: ${available && compatible ? 'pointer' : 'not-allowed'};
        opacity: ${available && compatible ? 1 : 0.5};
      `,
      text1: css`
        flex-shrink: 0;
        margin-right: ${token.marginXXS}px;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `,
      text2: css`
        margin-right: ${token.marginXXS}px;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${token.colorTextDescription};
      `,
      div: css`
        flex-grow: 1;
        justify-content: end;
        display: flex;
      `,
      warning: css`
        margin-right: 5px;
      `,
    }),
    [available, compatible, token]
  );

  return (
    <Col lg={12} xl={8} className={styles.col}>
      <Checkbox id={id} checked={selected} disabled={!available || !compatible} onChange={onChange} />
      <label htmlFor={id} className={styles.label}>
        <span className={styles.text1}>{flipParamNames ? sheetParam.longName : sheetParam.shortName}</span>
        <span className={styles.text2}>
          <small>{flipParamNames ? sheetParam.shortName : sheetParam.longName}</small>
        </span>
      </label>
      <div className={styles.div}>
        {available && compatible && (
          <Button
            icon={<InfoCircleOutlined twoToneColor={token.colorInfo} />}
            size="small"
            type="text"
            onClick={() => onInfo(requestableParam!)}
          />
        )}
        {(!available || !compatible) && (
          <Tooltip
            title={
              available && !compatible
                ? 'Der Parameter kann nicht angefordert werden, da er mit den ausgewählten Parametern nicht kompatibel ist.'
                : `Der Parameter kann aktuell nicht angefordert werden. ${
                    reorder
                      ? 'Nachforderung nicht möglich.'
                      : 'Bitte kontaktieren Sie das Labor für weitere Informationen.'
                  }`
            }
          >
            <WarningTwoTone twoToneColor={token.colorWarning} className={styles.warning} />
          </Tooltip>
        )}
      </div>
    </Col>
  );
};

export const MemoSheetParameterCol = memo(SheetParameterCol);
