import React, { useMemo } from 'react';
import { Collapse, CollapseProps, theme, Typography } from 'antd';
import { css } from '@emotion/css';
import { SheetGroupCard } from './SheetGroupCard.tsx';
import {
  ParameterSelection_DoctorSheetFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../../graphql/generated/graphql.ts';
import { SheetData } from '../../../../graphql/sheet';
import { SelectedParamInfo } from '../Sheets.tsx';

export const SheetAccordion: React.FC<{
  sheet: ParameterSelection_DoctorSheetFragment;
  sheetParamLookup: Record<string, ParameterSelection_RequestableParameterFragment | undefined>;
  selectedParamInfo: SelectedParamInfo;
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({ sheet, sheetParamLookup, selectedParamInfo, onAdd, onRemove, onInfo, reorder, flipParamNames }) => {
  const { token } = theme.useToken();

  const items: CollapseProps['items'] = useMemo(
    () =>
      sheet.data.map((sheetData: SheetData, index: number) => ({
        key: index,
        label: sheetData.name,
        extra: sheet.labId && <Typography.Text type="secondary">{sheet.lab?.name}</Typography.Text>,
        children: sheetData.groups.map((group, index) => (
          <SheetGroupCard
            key={index}
            sheet={sheetData}
            group={group}
            sheetParamLookup={sheetParamLookup}
            selectedParamInfo={selectedParamInfo}
            onAdd={onAdd}
            onRemove={onRemove}
            onInfo={onInfo}
            reorder={reorder}
            flipParamNames={flipParamNames}
          />
        )),
      })) ?? [],
    [flipParamNames, onAdd, onInfo, onRemove, reorder, selectedParamInfo, sheet, sheetParamLookup]
  );

  return (
    <Collapse
      // destroyInactivePanel do not destroy to avoid re-rendering the DOM
      size="small"
      accordion
      items={items}
      className={css`
        .ant-collapse-header-text {
          font-weight: 600;
        }
        margin-bottom: ${token.marginSM}px;
      `}
    />
  );
};
