import React, { memo, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Empty, Input, Table, Typography } from 'antd';
import { tableHoverPointer } from '../../../styles/globalCss';
import { css, cx } from '@emotion/css';
import { CheckOutlined, SearchOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import {
  ParameterSelection_ProfileFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../graphql/generated/graphql.ts';
import { ProfileDetails } from './ProfileDetails.tsx';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';

const Profiles: React.FC<{
  params: readonly ParameterSelection_RequestableParameterFragment[];
  profiles: readonly ParameterSelection_ProfileFragment[];
  selectedParams: SelectedParameter[];
  onAddProfile: (profile: ParameterSelection_ProfileFragment) => void;
  onAddParam: (paramInput: ParameterToAddInput) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  processing: boolean;
  flipParamNames: boolean;
}> = ({ params, profiles, selectedParams, onAddProfile, onAddParam, onRemove, onInfo, processing, flipParamNames }) => {
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [filteredProfiles, setFilteredProfiles] = useState<ParameterSelection_ProfileFragment[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  useEffect(() => {
    setFilteredProfiles(
      profiles.filter(it => {
        if (debouncedSearch && debouncedSearch.length) {
          const searchValue = debouncedSearch.toLowerCase();
          return it.name.toLowerCase().includes(searchValue);
        }
        return true;
      }) ?? []
    );
  }, [debouncedSearch, profiles]);

  const columns: ColumnsType<ParameterSelection_ProfileFragment> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 120,
      render: value => (
        <Typography.Text style={{ fontWeight: 500 }}>
          <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>
        </Typography.Text>
      ),
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Tastaturkürzel',
      dataIndex: 'hotKey',
      key: 'hotKey',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Anzahl Parameter',
      key: 'paramCount',
      ellipsis: true,
      width: 140,
      render: (_, record) => record.parameters.length,
    },
    {
      title: 'Ausgewählt',
      key: 'selected',
      ellipsis: true,
      fixed: 'right',
      align: 'center',
      width: 100,
      render: (_, record) =>
        record.parameters.every(profileParam =>
          selectedParams.some(selectedParam => selectedParam.mainParameterId === profileParam.parameter.id)
        ) ? (
          <CheckOutlined />
        ) : (
          ''
        ),
    },
  ];

  return (
    <>
      <Input
        allowClear
        placeholder="Suche nach Bezeichnung"
        value={search ?? ''}
        onChange={e => setSearch(e.target.value)}
        prefix={<SearchOutlined />}
      />
      <p />
      <Table<ParameterSelection_ProfileFragment>
        expandable={{
          expandIcon: data => (
            <div
              className={css`
                position: absolute;
                inset: 0 0 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
              onClick={e => {
                e.stopPropagation();
                data.onExpand(data.record, e);
              }}
            >
              {data.expanded ? <DownOutlined /> : <RightOutlined />}
            </div>
          ),
          columnWidth: 30,
          fixed: 'left',
          expandedRowKeys: expandedKeys,
          onExpand: (expanded, record) => (expanded ? setExpandedKeys([record.id]) : setExpandedKeys([])),
          expandedRowRender: record => (
            <ProfileDetails
              params={params}
              selectedParams={selectedParams}
              profile={record}
              flipParamNames={flipParamNames}
              onAdd={onAddParam}
              onRemove={onRemove}
              onInfo={onInfo}
            />
          ),
        }}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={false}
        rowKey={record => record.id}
        size="small"
        sticky
        className={css`
          overflow-y: auto;
        `}
        dataSource={filteredProfiles}
        pagination={false}
        loading={processing}
        columns={columns}
        onRow={record => ({
          onClick: () => onAddProfile(record),
        })}
        locale={{
          emptyText: <Empty description="Es wurden noch keine Profile angelegt" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
        rowClassName={cx(
          tableHoverPointer,
          css`
            .ant-table-row-expand-icon-cell {
              padding: 0 !important;
            }
          `
        )}
      />
    </>
  );
};

export const MemoProfiles = memo(Profiles);
