import React, { memo, useMemo } from 'react';
import { SelectedParameter } from '../../../hooks/store/useAppStore.ts';
import { SheetAccordion } from './sheets/SheetAccordion.tsx';
import { css } from '@emotion/css';
import { Empty } from 'antd';
import {
  ParameterSelection_DoctorSheetFragment,
  ParameterSelection_RequestableParameterFragment,
  ParameterToAddInput,
} from '../../../graphql/generated/graphql.ts';
import { SheetData } from '../../../graphql/sheet.ts';

export type SelectedParamInfo = {
  selectedParamMainIds: Record<string, string>;
  withoutParameterIds: Record<string, string>;
};

const Sheets: React.FC<{
  sheets: readonly ParameterSelection_DoctorSheetFragment[];
  params: readonly ParameterSelection_RequestableParameterFragment[];
  selectedParams: SelectedParameter[];
  onAdd: (inputs: ParameterToAddInput[]) => void;
  onRemove: (paramId: string) => void;
  onInfo: (param: ParameterSelection_RequestableParameterFragment) => void;
  reorder: boolean;
  flipParamNames: boolean;
}> = ({ sheets, params, selectedParams, onAdd, onRemove, onInfo, reorder, flipParamNames }) => {
  const doctorSheet = useMemo(() => sheets.find(it => !!it.doctorId), [sheets]);
  const labSheets = useMemo(() => sheets.filter(it => !!it.labId), [sheets]);

  /** performance improvement */
  const sheetParamLookup = useMemo(() => {
    const map: Record<string, ParameterSelection_RequestableParameterFragment | undefined> = {};
    sheets.forEach(sheet => {
      sheet.data.forEach((sheetData: SheetData) => {
        sheetData.groups.forEach(group => {
          group.parameters.forEach(sheetParam => {
            map[sheetParam.id] = params.find(it => it.mainParameterId === sheetParam.id);
          });
        });
      });
    });
    return map;
  }, [params, sheets]);

  /** performance improvement */
  const selectedParamInfo: SelectedParamInfo = useMemo(
    () => ({
      selectedParamMainIds: selectedParams.map(it => it.mainParameterId).reduce((a, v) => ({ ...a, [v]: v }), {}),
      withoutParameterIds: selectedParams
        .flatMap(sp => sp.withoutParameterIds)
        .reduce((a, v) => ({ ...a, [v]: v }), {}),
    }),
    [selectedParams]
  );

  if (!doctorSheet?.data.length && !labSheets.some(it => it.data.length)) {
    return <Empty description="Keine Sheets verfügbar" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div
      className={css`
        overflow-y: auto;
      `}
    >
      {doctorSheet && doctorSheet.data.length > 0 && (
        <SheetAccordion
          sheet={doctorSheet}
          sheetParamLookup={sheetParamLookup}
          selectedParamInfo={selectedParamInfo}
          onAdd={onAdd}
          onRemove={onRemove}
          onInfo={onInfo}
          reorder={reorder}
          flipParamNames={flipParamNames}
        />
      )}
      {labSheets
        .filter(it => it.data.length > 0)
        .map(labSheet => (
          <SheetAccordion
            key={labSheet.labId}
            sheet={labSheet}
            sheetParamLookup={sheetParamLookup}
            selectedParamInfo={selectedParamInfo}
            onAdd={onAdd}
            onRemove={onRemove}
            onInfo={onInfo}
            reorder={reorder}
            flipParamNames={flipParamNames}
          />
        ))}
    </div>
  );
};

export const MemoSheets = memo(Sheets);
