import React, { memo, useMemo } from 'react';
import { App, Badge, Button, Divider, Dropdown, Empty, List, theme } from 'antd';
import {
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  RightOutlined,
  SortAscendingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { billingTypePriority, testTubeColorProperties } from '../../../utils/enumHelpers';
import { TestTubeColor } from '../../../components/TestTubeColor';
import { BillingInfoComponent } from '../BillingInfoComponent';
import { useQuery } from '@apollo/client';
import { BillingTypeWithBadge } from '../../../components/BillingTypeWithBadge';
import { PageHeader } from '@ant-design/pro-components';
import { css, cx } from '@emotion/css';
import { AliasToken } from 'antd/es/theme/interface';
import { SelectedParameter } from '../../../hooks/store/useAppStore';
import { ItemType } from 'antd/es/menu/interface';
import { graphql } from '../../../graphql/generated';
import {
  ParameterSelection_RequestableParameterFragment,
  RequestForReorderQuery,
} from '../../../graphql/generated/graphql.ts';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';
import { SelectedParamsSortOrder, useUserSettingsStore } from '../../../hooks/store/useUserSettingsStore.ts';
import { CostsSummary } from '../CostsSummary';

const { useToken } = theme;

type Lab = ParameterSelection_RequestableParameterFragment['lab'];

interface SelectedParamsProps {
  showPrices: boolean;
  flipParamNames: boolean;
  selectedParams: SelectedParameter[];
  requestIdForReorder: string;
  showPriceSuggestion: boolean;
  onNext: () => void;
  onClear: () => void;
  onEdit: (param: SelectedParameter) => void;
  onRemove: (paramId: string) => void;
  onInfo: (paramId: string) => void;
  onNewProfile: () => void;
}

interface LabParamListProps {
  showPrices: boolean;
  flipParamNames: boolean;
  lab: Lab;
  selectedParams: SelectedParameter[];
  onEdit: (param: SelectedParameter) => void;
  onRemove: (paramId: string) => void;
  onInfo: (paramId: string) => void;
  sortBy: SelectedParamsSortOrder;
}

export const REQUEST_FOR_REORDER_QUERY = graphql(`
  query RequestForReorder($id: ID!) {
    request(id: $id) {
      id
      orders {
        id
        orderParameters {
          id
          shortName
          longName
          billingType
        }
        lab {
          id
          name
        }
      }
    }
  }
`);

const buildStyles = (token: AliasToken) => ({
  list: css`
    .ant-list-item {
      padding: ${token.paddingXXS}px ${token.paddingSM}px;
    }

    .ant-list-item-meta-title {
      margin: 0 !important;
    }

    .ant-list-item-action {
      margin-left: ${token.paddingSM}px !important;
    }

    .ant-list-item-action > li {
      padding: 0 !important;
    }

    .ant-list-item-action-split {
      display: none;
    }
  `,
  ellipsisText: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: ${token.paddingXS}px;
  `,
  acuteInfo: css`
    margin-right: ${token.paddingXS}px;
  `,
  descriptionWrapper: css`
    display: flex;
    font-size: ${token.fontSizeSM}px;
  `,
  divider1: css`
    margin-top: 0;
    margin-bottom: ${token.paddingSM}px;
  `,
  divider2: css`
    margin-top: ${token.paddingSM}px;
    margin-bottom: ${token.paddingSM}px;
  `,
  nextButton: css`
    width: 100%;
    margin-bottom: ${token.paddingXS}px; // show button click border
  `,
  reorder: css`
    margin-top: ${token.paddingSM}px;
  `,
  pageHeader: css`
    padding: ${token.paddingMD}px 0 0 0;
  `,
  listWrapper: css`
    overflow-y: auto;
    flex-grow: 1;
  `,
  actionButton: css`
    width: 25px !important;
    height: 25px;
  `,
  billingInfoWrapper: css`
    align-content: center;
  `,
});

const LabParamList: React.FC<LabParamListProps> = ({
  showPrices,
  flipParamNames,
  lab,
  selectedParams,
  onEdit,
  onRemove,
  onInfo,
  sortBy,
}) => {
  const { token } = useToken();
  const styles = buildStyles(token);

  const sortedParams = useMemo(() => {
    if (sortBy === 'lastAdded') {
      // no need to sort
      return selectedParams;
    }
    if (sortBy === 'firstAdded') {
      return [...selectedParams].reverse();
    }
    return [...selectedParams].sort((a, b) => {
      if (sortBy === 'shortNameAsc') {
        return a.shortName.localeCompare(b.shortName);
      }
      if (sortBy === 'shortNameDesc') {
        return b.shortName.localeCompare(a.shortName);
      }
      if (sortBy === 'longNameAsc') {
        return a.longName.localeCompare(b.longName);
      }
      if (sortBy === 'longNameDesc') {
        return b.longName.localeCompare(a.longName);
      }
      if (sortBy === 'priceAsc') {
        return (a.billingInfo.price ?? -1) - (b.billingInfo.price ?? -1);
      }
      if (sortBy === 'priceDesc') {
        return (b.billingInfo.price ?? -1) - (a.billingInfo.price ?? -1);
      }
      if (sortBy === 'billingType') {
        return billingTypePriority(a.billingInfo.billingType) - billingTypePriority(b.billingInfo.billingType);
      }
      return flipParamNames ? a.longName.localeCompare(b.longName) : a.shortName.localeCompare(b.shortName);
    });
  }, [selectedParams, flipParamNames, sortBy]);

  return (
    <List
      size="small"
      className={styles.list}
      itemLayout="horizontal"
      dataSource={sortedParams}
      header={lab.name}
      renderItem={selectedParam => (
        <List.Item
          actions={[
            <Button
              key="info"
              icon={<InfoCircleOutlined />}
              type="text"
              className={styles.actionButton}
              onClick={() => onInfo(selectedParam.id)}
            />,
            <Button
              key="edit"
              icon={<EditOutlined />}
              type="text"
              className={styles.actionButton}
              onClick={() => onEdit(selectedParam)}
            />,
            <Button
              key="remove"
              danger
              icon={<DeleteOutlined />}
              type="text"
              className={styles.actionButton}
              onClick={() => onRemove(selectedParam.id)}
            />,
          ]}
        >
          <List.Item.Meta
            title={
              <div className={styles.ellipsisText}>
                {flipParamNames
                  ? `${selectedParam.longName} - ${selectedParam.shortName}`
                  : `${selectedParam.shortName} - ${selectedParam.longName}`}
              </div>
            }
            description={
              <div className={styles.descriptionWrapper}>
                {selectedParam.specimens
                  .flatMap(it => it.testTubes)
                  .map((tt, i) => {
                    return (
                      <TestTubeColor
                        key={tt.id + '_' + i}
                        properties={testTubeColorProperties(tt.color)}
                        withText={false}
                      />
                    );
                  })}
                <div className={cx(styles.billingInfoWrapper, styles.ellipsisText)}>
                  <BillingInfoComponent billingInfo={selectedParam.billingInfo} showPrice={showPrices} />
                </div>
                {selectedParam.acute ? <ClockCircleOutlined className={styles.acuteInfo} /> : null}
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

const OrderParamList: React.FC<{
  lab: { id: string; name: string };
  params: NonNullable<RequestForReorderQuery['request']>['orders'][number]['orderParameters'];
  flipParamNames: boolean;
}> = ({ lab, params, flipParamNames }) => {
  const { token } = useToken();
  const styles = buildStyles(token);

  return (
    <List
      size="small"
      className={styles.list}
      itemLayout="horizontal"
      dataSource={[...params].sort((a, b) => a.shortName.localeCompare(b.shortName))}
      header={lab.name}
      renderItem={orderParam => (
        <List.Item>
          <List.Item.Meta
            title={
              <div className={styles.ellipsisText}>
                {flipParamNames
                  ? `${orderParam.longName} - ${orderParam.shortName}`
                  : `${orderParam.shortName} - ${orderParam.longName}`}
              </div>
            }
            description={
              <div className={styles.descriptionWrapper}>
                <BillingTypeWithBadge billingType={orderParam.billingType} />
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

const SelectedParams: React.FC<SelectedParamsProps> = ({
  flipParamNames,
  showPrices,
  selectedParams,
  requestIdForReorder,
  showPriceSuggestion,
  onNext,
  onClear,
  onEdit,
  onRemove,
  onInfo,
  onNewProfile,
}) => {
  const { token } = useToken();
  const styles = useMemo(() => buildStyles(token), [token]);
  const { modal } = App.useApp();
  const { selectedParamsSortOrder, setSelectedParamsSortOrder } = useUserSettingsStore();

  const labs = selectedParams
    .reduce<Lab[]>((labs, param) => (labs.some(l => l.id === param.lab.id) ? labs : [...labs, param.lab]), [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const { data, loading } = useQuery(REQUEST_FOR_REORDER_QUERY, {
    variables: {
      id: requestIdForReorder,
    },
    skip: !requestIdForReorder,
    fetchPolicy: 'cache-and-network',
  });

  const orderLabs =
    data?.request?.orders
      ?.reduce<
        { id: string; name: string }[]
      >((labs, order) => (labs.some(l => l.id === order.lab.id) ? labs : [...labs, order.lab]), [])
      ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const dropDownItems: ItemType[] = [
    {
      label: 'Sortierung',
      key: 'sort',
      icon: <SortAscendingOutlined />,
      children: [
        {
          label: 'Zuletzt hinzugefügt oben',
          key: 'sort.lastAdded',
          onClick: () => setSelectedParamsSortOrder('lastAdded'),
        },
        {
          label: 'Zuletzt hinzugefügt unten',
          key: 'sort.firstAdded',
          onClick: () => setSelectedParamsSortOrder('firstAdded'),
        },
        {
          type: 'divider',
        },
        {
          label: 'Kurzname aufsteigend',
          key: 'sort.shortNameAsc',
          onClick: () => setSelectedParamsSortOrder('shortNameAsc'),
        },
        {
          label: 'Kurzname absteigend',
          key: 'sort.shortNameDesc',
          onClick: () => setSelectedParamsSortOrder('shortNameDesc'),
        },
        {
          type: 'divider',
        },
        {
          label: 'Langbezeichnung aufsteigend',
          key: 'sort.longNameAsc',
          onClick: () => setSelectedParamsSortOrder('longNameAsc'),
        },
        {
          label: 'Langbezeichnung absteigend',
          key: 'sort.longNameDesc',
          onClick: () => setSelectedParamsSortOrder('longNameDesc'),
        },
        {
          type: 'divider',
        },
        {
          label: 'Preis aufsteigend',
          key: 'sort.priceAsc',
          onClick: () => setSelectedParamsSortOrder('priceAsc'),
        },
        {
          label: 'Preis absteigend',
          key: 'sort.priceDesc',
          onClick: () => setSelectedParamsSortOrder('priceDesc'),
        },
        {
          type: 'divider',
        },
        {
          label: 'Verrechnungstyp',
          key: 'sort.billingType',
          onClick: () => setSelectedParamsSortOrder('billingType'),
        },
      ],
    },
    {
      label: 'Neues Profil',
      key: 'profile',
      disabled: selectedParams.length <= 0,
      icon: <StarOutlined />,
      onClick: onNewProfile,
    },
    {
      label: 'Alle entfernen',
      danger: true,
      key: 'delete',
      disabled: selectedParams.length <= 0,
      icon: <DeleteOutlined />,
      onClick: () => {
        modal.confirm({
          maskClosable: true,
          title: 'Alle Parameter entfernen?',
          content: 'Wollen Sie wirklich alle bereits ausgewählten Parameter entfernen?',
          okText: 'Ja, Parameter entfernen',
          cancelText: 'Nein',
          okButtonProps: { icon: <DeleteOutlined /> },
          onOk: onClear,
        });
      },
    },
  ];

  return (
    <>
      <PageHeader
        title={requestIdForReorder ? 'Nachforderung' : 'Anforderung'}
        subTitle={<Badge showZero count={selectedParams.length} style={{ backgroundColor: token.colorPrimary }} />}
        extra={
          <Dropdown
            menu={{ items: dropDownItems, selectable: true, selectedKeys: [`sort.${selectedParamsSortOrder}`] }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button icon={<EllipsisOutlined style={{ fontSize: '20px' }} />} />
          </Dropdown>
        }
        className={styles.pageHeader}
      />
      <div className={styles.listWrapper}>
        {!labs.length && <Empty description="Keine Parameter ausgewählt" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {labs.map(lab => {
          const labParams = selectedParams.filter(p => p.lab.id === lab.id);
          return (
            <LabParamList
              key={lab.id}
              lab={lab}
              selectedParams={labParams}
              onEdit={onEdit}
              onRemove={onRemove}
              onInfo={onInfo}
              flipParamNames={flipParamNames}
              showPrices={showPrices}
              sortBy={selectedParamsSortOrder}
            />
          );
        })}
        {requestIdForReorder && (
          <>
            <h3 className={styles.reorder}>Anforderung</h3>
            {loading ? (
              <LoadingIndicator height="200px" />
            ) : (
              orderLabs.map(lab => {
                const labParams =
                  data?.request?.orders?.filter(it => it.lab.id === lab.id)?.flatMap(it => it.orderParameters) ?? [];
                return <OrderParamList key={lab.id} lab={lab} params={labParams} flipParamNames={flipParamNames} />;
              })
            )}
          </>
        )}
      </div>
      {showPrices && (
        <>
          <Divider className={styles.divider1} />
          <CostsSummary
            entries={selectedParams.map(it => ({
              billingType: it.billingInfo.billingType,
              price: it.billingInfo.price ?? null,
              text: it.billingInfo.text,
              pricePatient: it.pricePatient ?? null,
            }))}
            showPriceSuggestion={showPriceSuggestion}
          />
        </>
      )}
      <Divider className={styles.divider2} />
      <Button
        disabled={!selectedParams.length}
        className={styles.nextButton}
        onClick={onNext}
        type="primary"
        icon={<RightOutlined />}
      >
        Weiter zur Übersicht
      </Button>
    </>
  );
};

export const MemoSelectedParams = memo(SelectedParams);
